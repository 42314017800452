@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--transition-base: opacity .25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.wrapper {
	position: relative;
	min-width: 410px;
	opacity: 1;
	transition: var(--transition-base);
}

.done .wrapper,
.error .wrapper {
	opacity: 0;
	pointer-events: none;
}

.form-body {
	transition: var(--transition-base);
	transition-delay: 3s;
	opacity: 1;
}

.wrapper:has(.disabled) .spinner {
	opacity: 1;
}

.done .popup-success,
.error .popup-warning {
	opacity: 1;
}

.popup {
	opacity: 0;
	transition: var(--transition-base);
	translate: 0 -50%;
	top: 50%;
	pointer-events: none;
}

.popup-icon {
	--tw-text-opacity: 1;
	--tw-bg-opacity: 1;
	padding: 7px;
	border-radius: 50%;
	width: 35px;
	aspect-ratio: 1;
	display: grid;
}

.popup-icon__success {
	background-color: rgb(220 252 231 / var(--tw-bg-opacity));
	color: rgb(74 222 128 / var(--tw-text-opacity));
}

.popup-icon__warning {
	background-color: rgb(254 226 226 / var(--tw-bg-opacity));
	color: rgb(220 38 38 / var(--tw-text-opacity));
}

.warning,
.success {
	grid-row: 1 / 2;
	grid-column: 1 / 2;
	opacity: 0;
}

.popup-icon__success .success {
	opacity: 1;
}

.popup-icon__warning .warning {
	opacity: 1;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
	transition-delay: 0s;
}

.popup {
}

/* Spinners */
.spinner {
	--spinner-width: 80px;
	--spinner-height: var(--spinner-width);
	--spinner-dot-width: calc(var(--spinner-width) / 11.5);
	--spinner-dot-height: var(--spinner-dot-width);

	position: absolute;
	left: 50%;
	top: 50%;
	translate: -50% -50%;
	pointer-events: none;
	opacity: 0;
	transition: var(--transition-base);
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: var(--spinner-width);
	height: var(--spinner-height);
}

.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: calc(var(--spinner-width) / 2) calc(var(--spinner-height) / 2);
}

.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: var(--spinner-dot-width);
	height: var(--spinner-dot-height);
	border-radius: 50%;
	background: var(--tw-ring-color, lightblue);
	margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
	top: calc(var(--spinner-height) / 1.26984126984127);
	left: calc(var(--spinner-width) / 1.26984126984127);
}

.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
	top: calc(var(--spinner-height) / 1.176470588235294);
	left: calc(var(--spinner-width) / 1.428571428571429);
}

.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
	top: calc(var(--spinner-height) / 1.126760563380282);
	left: calc(var(--spinner-width) / 1.666666666666667);
}

.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
	top: calc(var(--spinner-height) / 1.111111111111111);
	left: calc(var(--spinner-width) / 2);
}

.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
	top: calc(var(--spinner-height) / 1.126760563380282);
	left: calc(var(--spinner-width) / 2.5);
}

.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
	top: calc(var(--spinner-height) / 1.176470588235294);
	left: calc(var(--spinner-width) / 3.333333333333333);
}

.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
	top: calc(var(--spinner-height) / 1.26984126984127);
	left: calc(var(--spinner-width) / 4.705882352941176);
}

.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
	top: calc(var(--spinner-height) / 1.428571428571429);
	left: calc(var(--spinner-width) / 6.666666666666667);
}

@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}